import { Component, render } from 'utils/jsx'
var $ = require("jquery");
window.jQuery = $;
window.$ = $;
// typical import
import gsap from "gsap";
import mediumZoom from 'medium-zoom'
// or get other plugins:
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";


// Mobile menu
$('#toggle').click(function () {
  $(this).toggleClass('active')
  $('.overlay').toggleClass('open')
  $('header').toggleClass('overlay-open')
})

if (jQuery(window).width() >= 768) { // only on desktop
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  ScrollTrigger.normalizeScroll(true)

  // Smooth scroll
  const smoother = ScrollSmoother.create({
    content: "#smooth",
    smooth: 1.2,
    effects: true
  });

  // Cursor functionality
  var ball = document.getElementById("ball");
  var cursorText = document.getElementById("cursor-text");

  var hoverAreas = document.querySelectorAll('.hover-area');

  var lastHoveredElement = null;

  function updateCursor(e) {
    var x = e.clientX;
    var y = e.clientY;

    var scrollX = window.pageXOffset || document.documentElement.scrollLeft;
    var scrollY = window.pageYOffset || document.documentElement.scrollTop;

    ball.style.left = x + scrollX + "px";
    ball.style.top = y + scrollY + "px";
    cursorText.style.left = x + scrollX + "px";
    cursorText.style.top = y + scrollY + "px";

    if (lastHoveredElement === null) {
      ball.style.display = 'block';
      ball.style.height = "10px";
      ball.style.width = "10px";
      cursorText.style.opacity = '0';
    }
  }

  function handleHoverEnter(e) {
    cursorText.innerHTML = e.target.getAttribute('data-cursor-text');
    cursorText.style.opacity = '1';
    ball.style.height = "100px";
    ball.style.width = "100px";

    lastHoveredElement = e.target;
  }

  function handleHoverLeave(e) {
    cursorText.style.opacity = '0';
    ball.style.display = 'block';

    lastHoveredElement = null;
  }

  document.addEventListener('mousemove', updateCursor);

  hoverAreas.forEach(function (elem) {
    elem.addEventListener('mouseenter', handleHoverEnter);
    elem.addEventListener('mouseleave', handleHoverLeave);
  });

  // zoom images on profile 
  $(document).ready(function () {
    mediumZoom('.enlarge-image img')
  })
  /*
Show hide menu on scroll
=============================================== */
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = $("header").outerHeight();

  $(window).scroll(function (event) {
    didScroll = true;
    console.log("scrolling");

  });

  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;

    }
  }, 250);

  function hasScrolled() {
    var st = window.scrollY || document.documentElement.scrollTop;


    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) {
      return;
    }

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      $(".sticky")
        .removeClass("nav-down")
        .addClass("nav-up");
      $("header")
        .removeClass("nav-down")
        .addClass("nav-up -top-30");
      $('#toggle').removeClass('active')
      $('.overlay').removeClass('open')
      $('header').removeClass('overlay-open')
    } else {
      // Scroll Up
      if (st + $(window).height() < $(document).height()) {
        $(".sticky")
          .removeClass("nav-up")
          .addClass("nav-down");
        $("header")
          .removeClass("nav-up -top-30 ")
          .addClass("nav-down");
      }
    }
    lastScrollTop = st;
  }
}
